import {useLocation} from "react-router-dom";
import {useEffect} from "react";

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        const smoothScroll = () => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        };

        smoothScroll();
    }, [pathname]);

    return null;
};

export default ScrollToTop;