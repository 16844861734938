import React, {useEffect} from "react";
import {Indicator1} from "../components/Indicator";
import "../styles/pages/Product.css";
import "../styles/Style.css"
import {Title} from "../components/Title";
import {useTranslation} from "react-i18next";
import {ProductDetail} from "../components/ProductDetail";
import {ReactComponent as Info} from "../assets/product/info.svg";
import {ReactComponent as Composition} from "../assets/product/composition.svg";
import {ReactComponent as Formula} from "../assets/product/formula.svg";
import {ReactComponent as Purpose} from "../assets/product/purpose.svg";

export const Compound = () => {
    const json = "compound"
    const {t} = useTranslation(json);

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);

    return (
        <div className="product">
            <Indicator1 text={t("indicator_name")}/>
            <Title name={t("main_title")}/>
            <div className="product-container">
                <ProductDetail
                    json={json}
                    Icon={Info}
                    name="overview"
                />
                <ProductDetail
                    json={json}
                    Icon={Composition}
                    name="composition"
                />
                <ProductDetail
                    json={json}
                    Icon={Formula}
                    name="formulation"
                />
                <ProductDetail
                    json={json}
                    Icon={Purpose}
                    name="purpose"
                />
            </div>
        </div>
    );
};
