import React, { useState } from 'react';
import "../styles/components/Map.css";
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import { Address } from "./Address";
import {useTranslation} from "react-i18next";

export default function SammixMap() {
    const defaultState = {
        center: [39.689303, 67.015705],
        zoom: 6.5,
    };
    const {t} = useTranslation('address');
    const [activeAddress, setActiveAddress] = useState(null);

    const handleAddressClick = (name) => {
        setActiveAddress(activeAddress === name ? null : name);
    };

    return (
        <div className="map">
            <div className="map-component">
                <div className="address-container">
                    <Address
                        name={t("samarkand")}
                        number="+998 (99) 273 70 00"
                        title={t("samarkand_title")}
                        address={t("samarkand_address")}
                        mail="afrosiyobparranda@sammix.uz"
                        link="https://yandex.com/maps/-/CDfGBOnN"
                        isActive={activeAddress === "Samarqand"} onClick={() => handleAddressClick("Samarqand")} />
                    <Address
                        name={t("tashkent")}
                        number="+998 (70) 201 00 02"
                        title={t("tashkent_title")}
                        address={t("tashkent_address")}
                        mail="info@sammix.uz"
                        link="https://yandex.com/maps/-/CDfGBW5o"
                        isActive={activeAddress === "Tashkent"} onClick={() => handleAddressClick("Tashkent")} />
                    <Address
                        name={t("andijan")}
                        number="+998 (95) 324 70 00"
                        title={t("andijan_title")}
                        address={t("andijan_address")}
                        mail="afrosiyob.andijon@sammix.uz"
                        link="https://yandex.com/maps/-/CDfSbILM"
                        isActive={activeAddress === "Andijan"} onClick={() => handleAddressClick("Andijan")} />
                    <Address
                        name={t("surkhandaryo")}
                        number="+998 (55) 451 00 10"
                        title={t("surkhandaryo_title")}
                        address={t("surkhandaryo_address")}
                        mail="afrosiyob.termez@sammix.uz"
                        link="https://yandex.com/maps/-/CDfOJ0-v"
                        isActive={activeAddress === "Surkhandaryo"} onClick={() => handleAddressClick("Surkhandaryo")} />
                    <Address
                        name={t("andijan1")}
                        number="+998 (55) 451 00 10"
                        title={t("andijan1_title")}
                        address={t("andijan1_address")}
                        mail="broylers@sammix.uz"
                        link="https://yandex.uz/navi/-/CDfga8mk"
                        isActive={activeAddress === "Andijan1"} onClick={() => handleAddressClick("Andijan1")} />
                </div>
            </div>
            <YMaps>
                <Map defaultState={defaultState} className="yandex"
                     defaultOptions={{
                         suppressMapOpenBlock: true,
                         copyrightLogoVisible: false,
                         copyrightUaVisible: false,
                         copyrightProvidersVisible: false
                     }}>
                    <Placemark geometry={[39.689303, 67.015705]} defaultOptions={{
                        iconColor: "orange",
                    }} onClick={() => handleAddressClick("Samarqand")}/>
                    <Placemark geometry={[40.904775, 69.667472]} defaultOptions={{
                        iconColor: "orange",
                    }} onClick={() => handleAddressClick("Tashkent")}/>
                    <Placemark geometry={[37.313874, 67.222573]} defaultOptions={{
                        iconColor: "orange",
                    }} onClick={() => handleAddressClick("Surkhandaryo")}/>
                    <Placemark geometry={[40.900967, 72.248348]} defaultOptions={{
                        iconColor: "orange",
                    }} onClick={() => handleAddressClick("Andijan")}/>
                    <Placemark geometry={[40.568610, 72.190549]} defaultOptions={{
                        iconColor: "orange",
                    }} onClick={() => handleAddressClick("Andijan1")}/>
                </Map>
            </YMaps>
        </div>
    );
}
