import "../styles/components/Address.css";
import { ReactComponent as Plus } from "../assets/plus.svg";
import { ReactComponent as Minus } from "../assets/minus.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Address = ({ name, isActive, onClick, title, address, number, mail, link}) => {
    const {t} = useTranslation('address');
    return (
        <div className={`address ${isActive ? 'active' : 'hover'}`} onClick={onClick}>
            <div className="address-header">
                <div className="address-text">
                    {name}
                </div>
                {isActive ? <Minus className="address-icon" /> : <Plus className="address-icon" />}
            </div>
            {isActive && (
                <div className="address-details">
                    <div className="address-details-title">{title}</div>
                    <div className="address-details-address">
                        {address}
                    </div>
                    <Link to={"tel:" + number} target="_blank" rel="noopener noreferrer" className="address-details-link">{number}</Link>
                    <Link to={"mailto:" + mail} target="_blank" rel="noopener noreferrer" className="address-details-link">{mail}</Link>
                    <Link to={link} target="_blank" rel="noopener noreferrer" className="address-details-link">{t("map")}</Link>
                </div>
            )}
        </div>
    );
};
