import {createContext, useState} from "react";

export const MobileMenuContext = createContext();

export const MobileMenuProvider = ({children}) => {
    const [click, setClick] = useState(false);
    const [productClick, setProductClick] = useState(false);
    const [prescriptionClick, setPrescriptionClick] = useState(false);
    const [productsOpen, setProductsOpen] = useState(false);
    const [prescriptionOpen, setPrescriptionOpen] = useState(false);

    const handleClick = () => {
        setClick(!click);
        setPrescriptionOpen(false);
        setProductsOpen(false);
        setProductClick(false);
        setPrescriptionClick(false);
    };
    const closeMobileMenu = () => {
        setPrescriptionOpen(false);
        setProductsOpen(false);
        setClick(false);
        setProductClick(false);
        setPrescriptionClick(false);
    };

    const openPrescriptionMenu = () => {
        setPrescriptionClick(!prescriptionClick)
        setPrescriptionOpen(!prescriptionOpen);
    };

    const openProductsMenu = () => {
        setProductClick(!productClick)
        setProductsOpen(!productsOpen);
    };

    return (
        <MobileMenuContext.Provider
            value={{
                productClick,
                prescriptionClick,
                click,
                handleClick,
                closeMobileMenu,
                openPrescriptionMenu,
                productsOpen,
                prescriptionOpen,
                openProductsMenu,
            }}
        >
            {children}
        </MobileMenuContext.Provider>
    );
};
