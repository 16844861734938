import React, {useEffect} from 'react';
import {Indicator} from "../components/Indicator";
import "../styles/pages/Contacts.css"
import "../styles/Style.css"
import {Title} from "../components/Title";
import {useTranslation} from 'react-i18next';
import SammixMap from "../components/SammixMap";

export const Contacts = () => {
    const {t} = useTranslation("contact");

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);

    return (
        <div className="contacts">
            <Indicator text={t('indicator_name')}/>
            <Title name={t('main_title')}/>
            <SammixMap/>
        </div>
    );
}
