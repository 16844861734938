import React, {useEffect} from 'react';
import "../styles/pages/Loading.css"
import {useTranslation} from "react-i18next";

export const Loading = () => {
    const {t} = useTranslation('loading');
    useEffect(() => {
        document.title = t('page_title');
    }, [t]);

    return (
        <div className="loading-container">
            <div className="loading-spinner">
                <div id="square1"></div>
                <div id="square2"></div>
                <div id="square3"></div>
                <div id="square4"></div>
                <div id="square5"></div>
            </div>
        </div>
    );
}