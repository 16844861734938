import React, {useEffect, useState} from "react";
import "../styles/pages/Prescription.css";
import "../styles/Style.css"
import {useTranslation} from "react-i18next";
import {Indicator1} from "../components/Indicator";
import {Title} from "../components/Title";
import {Receipt} from "../components/Receipt";
import {ReceiptText} from "../components/ReceiptText";


export const Vitamins = () => {
    const json = "vitamins"
    const {t} = useTranslation(json);
    const [activeReceiptItem, setActiveReceiptItem] = useState("vitamin_a");

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);
    const handleClick = (itemName) => {
        setActiveReceiptItem(itemName);
    };
    return (
        <div className="prescription">
            <Indicator1 text={t("indicator_name")}/>
            <Title name={t("main_title")}/>
            <div className="receipt-container">
                <div onClick={() => handleClick("vitamin_a")}>
                    <Receipt text={"vitamin_a"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_bc")}>
                    <Receipt text={"vitamin_bc"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_b1")}>
                    <Receipt text={"vitamin_b1"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_b2")}>
                    <Receipt text={"vitamin_b2"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_b3")}>
                    <Receipt text={"vitamin_b3"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_b5")}>
                    <Receipt text={"vitamin_b5"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_b6")}>
                    <Receipt text={"vitamin_b6"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_b12")}>
                    <Receipt text={"vitamin_b12"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_c")}>
                    <Receipt text={"vitamin_c"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_d3")}>
                    <Receipt text={"vitamin_d3"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_e")}>
                    <Receipt text={"vitamin_e"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_k3_stab")}>
                    <Receipt text={"vitamin_k3_stab"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_k3_msb")}>
                    <Receipt text={"vitamin_k3_msb"} json={json}/>
                </div>
                <div onClick={() => handleClick("vitamin_h")}>
                    <Receipt text={"vitamin_h"} json={json}/>
                </div>
            </div>
            <ReceiptText json={json} text={activeReceiptItem}/>
        </div>
    );
};
