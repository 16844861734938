import React from 'react';
import "../styles/components/Value.css";

export const Value = (props) => {
    const {red, Icon, title, text} = props
    const valueClasses = red ? 'value white-background' : 'value';
    const iconColorClass = red ? 'icon-red' : '';
    const titleColorClass = red ? 'red' : '';
    const textColorClass = red ? 'red' : '';

    return (
        <div className={valueClasses}>
            <div className={`value-icon ${iconColorClass}`}>
                <Icon/>
            </div>
            <div className={`value-title ${titleColorClass}`}>
                {title}
            </div>
            <div className={`value-text ${textColorClass}`}>
                {text}
            </div>
        </div>
    );
}
