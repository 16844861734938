import React from "react";
import "../styles/components/ReceiptText.css"
import {useTranslation} from "react-i18next";

export const ReceiptText = (props) => {
    const {json, text} = props
    const {t} = useTranslation(json);
    return (
        <div className="receipt-info">
            <div className="receipt-title">
                {t(text)}
            </div>
            <div className="receipt-text" dangerouslySetInnerHTML={{__html: t(text.concat("_text"))}}/>
        </div>
    );
}