import slash from "../assets/slash.png"
import {Link} from "react-router-dom";
import React, {useContext} from "react";
import "../styles/components/Indicator.css"
import {MobileMenuContext} from "./MobileMenu";
import {useTranslation} from "react-i18next";

export const Indicator = (props) => {
    const {t} = useTranslation("navbar");
    const {text} = props;
    const {closeMobileMenu} = useContext(MobileMenuContext);

    return (
        <div className="navigation-path">
            <Link className="navigation-path-text indicator-red" to={"/"} onClick={closeMobileMenu}>{t("home")}</Link>
            <img className="slash" src={slash} alt="slash"/>
            <div className="navigation-path-text indicator-grey">{text}</div>
        </div>
    );
}
export const Indicator1 = (props) => {
    const {t} = useTranslation("navbar");
    const {text, background} = props;
    const {closeMobileMenu} = useContext(MobileMenuContext);

    return (
        <div className={`navigation-path ${background}`}>
            <Link className="navigation-path-text indicator-red" to={"/"} onClick={closeMobileMenu}>{t("home")}</Link>
            <img className="slash" src={slash} alt="slash"/>
            <Link className="navigation-path-text indicator-red" to={"/products"} onClick={closeMobileMenu}>{t("products")}</Link>
            <img className="slash" src={slash} alt="slash"/>
            <div className="navigation-path-text indicator-grey">{text}</div>
        </div>
    );
}

