import React, {useEffect, useState} from "react";
import "../styles/pages/Prescription.css";
import "../styles/Style.css"
import {useTranslation} from "react-i18next";
import {Indicator1} from "../components/Indicator";
import {Title} from "../components/Title";
import {Receipt} from "../components/Receipt";
import {ReceiptText} from "../components/ReceiptText";


export const AminoAcids = () => {
    const json = "amino"
    const {t} = useTranslation(json);
    const [activeReceiptItem, setActiveReceiptItem] = useState("trytophan");

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);
    const handleClick = (itemName) => {
        setActiveReceiptItem(itemName);
    };
    return (
        <div className="prescription">
            <Indicator1 text={t("indicator_name")}/>
            <Title name={t("main_title")}/>
            <div className="receipt-container">
                <div onClick={() => handleClick("trytophan")}>
                    <Receipt text={"trytophan"} json={json}/>
                </div>
                <div onClick={() => handleClick("valine")}>
                    <Receipt text={"valine"} json={json}/>
                </div>
                <div onClick={() => handleClick("lysine")}>
                    <Receipt text={"lysine"} json={json}/>
                </div>
                <div onClick={() => handleClick("arginine")}>
                    <Receipt text={"arginine"} json={json}/>
                </div>
                <div onClick={() => handleClick("threonine")}>
                    <Receipt text={"threonine"} json={json}/>
                </div>
                <div onClick={() => handleClick("methionine")}>
                    <Receipt text={"methionine"} json={json}/>
                </div>
            </div>
            <ReceiptText json={json} text={activeReceiptItem}/>
        </div>
    );
};
