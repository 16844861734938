import "../styles/components/Social.css"
import {Link} from "react-router-dom";
import {useContext} from "react";
import {MobileMenuContext} from "./MobileMenu";

import {ReactComponent as FacebookLogo} from '../assets/social/facebook.svg';
import {ReactComponent as LinkedinLogo} from '../assets/social/linkedin.svg';
import {ReactComponent as InstagramLogo} from '../assets/social/instagram.svg';

export const Social = () => {
    const {closeMobileMenu} = useContext(MobileMenuContext);
    return (
        <div className="social-links">
            <Link to="https://www.facebook.com/sammix.uz/" target="_blank" onClick={closeMobileMenu}>
                <FacebookLogo className="social-icon facebook"/>
            </Link>
            <Link to="https://www.instagram.com/sammix.uz/" target="_blank"  onClick={closeMobileMenu}>
                <InstagramLogo className="social-icon instagram"/>
            </Link>
            <Link to="https://www.linkedin.com/company/agro-sammix-llc/" target="_blank" onClick={closeMobileMenu}>
                <LinkedinLogo className="social-icon linkedin"/>
            </Link>
        </div>
    );
}
