import React from 'react';
import {useTranslation} from 'react-i18next';
import '../styles/components/LanguageSelector.css';

const LanguageSelector = () => {
    const {i18n} = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        changeLanguage(selectedLanguage);
    };

    return (
        <div className="language-selector">
            <select className="language-select" onChange={handleLanguageChange} value={i18n.language}>
                <option value="en">English</option>
                <option value="uz">O'zbekcha</option>
                <option value="ru">Русский</option>
            </select>
        </div>
    );
};

export default LanguageSelector;
