import React from 'react';
import "../styles/components/WhyUs.css";
import {Value} from "./Value";
import {ReactComponent as Brand} from "../assets/value/brand.svg";
import {ReactComponent as Leader} from "../assets/value/leader.svg";
import {ReactComponent as Quality} from "../assets/value/quality.svg";
import {ReactComponent as Facilities} from "../assets/value/facilities.svg";
import {ReactComponent as Solution} from "../assets/value/solution.svg";
import {ReactComponent as Trust} from "../assets/value/trust.svg";
import {useTranslation} from "react-i18next";

export const WhyUs = () => {
    const {t} = useTranslation('why');
    return (
        <div className="why-us">
            <div className="why-us-title">
                {t("why_us")}
            </div>
            <div className="values-container">
                <Value
                    Icon={Leader}
                    text={t("leader_text")}
                    title={t("leader_title")}
                />
                <Value
                    Icon={Brand}
                    red
                    text={t("pricing_text")}
                    title={t("pricing_title")}
                />
                <Value
                    Icon={Quality}
                    text={t("quality_text")}
                    title={t("quality_title")}
                />
                <Value
                    Icon={Facilities}
                    red
                    text={t("facilities_text")}
                    title={t("facilities_title")}
                />
                <Value
                    Icon={Solution}
                    text={t("personalized_text")}
                    title={t("personalized_title")}
                />
                <Value
                    Icon={Trust}
                    red
                    text={t("trusted_text")}
                    title={t("trusted_title")}
                />
            </div>
        </div>
    );
}
