import React from 'react';
import "../styles/components/Title.css"
import {ReactComponent as Underline} from "../assets/Underline.svg";

export const Title = ({name}) => {
    return (
        <div className="title-container">
            <h1 className="main-title">
                {name}
            </h1>
            <Underline className="subtitle-item"/>
        </div>
    );
}
