import {Link, NavLink} from "react-router-dom";
import {FaBars, FaMinus, FaPlus, FaTimes} from "react-icons/fa";
import "../styles/components/Navbar.css";
import {useContext} from "react";
import {MobileMenuContext} from "./MobileMenu";
import logo from "../assets/logo.png";
import {useTranslation} from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import {RiArrowDropDownLine} from "react-icons/ri";

const NavItem = ({to, text, t, onClick}) => {
    return (
        <li className="nav-item hover-effect">
            <NavLink
                to={to}
                className={({isActive}) =>
                    "nav-links " + (isActive ? " activated" : "")
                }
                onClick={onClick}
            >
                {t(text)}
            </NavLink>
        </li>
    );
};

export const Navbar = () => {
    const {t} = useTranslation("navbar");
    const {
        productClick,
        prescriptionClick,
        click,
        closeMobileMenu,
        handleClick,
        prescriptionOpen,
        productsOpen,
        openPrescriptionMenu,
        openProductsMenu,
    } = useContext(MobileMenuContext);

    return (
        <nav className="navbar">
            <Link to={"/"}>
                <img src={logo} alt="logo" onClick={closeMobileMenu}/>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
                {click ? <FaTimes/> : <FaBars/>}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
                <NavItem to="/" text="home" t={t} onClick={closeMobileMenu}/>
                <li
                    className={
                        productsOpen ? `nav-item dropdown open` : `nav-item dropdown`
                    }
                >
                    <NavLink
                        to={"/products"}
                        className={({isActive}) =>
                            "nav-links " + (isActive ? " activated" : "")
                        }>
                        {t("products")} <RiArrowDropDownLine className={"disable"}/>
                        <div className="dropdown-icon" onClick={openProductsMenu}>
                            {productClick ? <FaMinus/> : <FaPlus/>}
                        </div>
                    </NavLink>

                    <div
                        className={
                            productsOpen
                                ? "dropdown-content products open"
                                : "dropdown-content products"
                        }
                    >
                        <Link to={"/products/compound-feed"} onClick={closeMobileMenu}>
                            {t("compound")}
                        </Link>
                        <Link to={"/products/sammix-premix"} onClick={closeMobileMenu}>
                            {t("premix")}
                        </Link>
                    </div>
                </li>
                <li
                    className={
                        prescriptionOpen ? "nav-item dropdown open" : `nav-item dropdown`
                    }
                >
                    <NavLink
                        to={"/products"}
                        className={"nav-links"}
                    >
                        {t("prescriptions")} <RiArrowDropDownLine className={"disable"}/>
                        <div className="dropdown-icon" onClick={openPrescriptionMenu}>
                            {prescriptionClick ? <FaMinus/> : <FaPlus/>}
                        </div>
                    </NavLink>
                    <div
                        className={
                            prescriptionOpen
                                ? "dropdown-content prescription open"
                                : "dropdown-content prescription"
                        }
                    >
                        <Link to={"/products/enzymes"} onClick={closeMobileMenu}>
                            {t("enzymes")}
                        </Link>
                        <Link to={"/products/vitamins"} onClick={closeMobileMenu}>
                            {t("vitamins")}
                        </Link>
                        <Link to={"/products/colorants"} onClick={closeMobileMenu}>
                            {t("colorants")}
                        </Link>
                        <Link to={"/products/probiotics"} onClick={closeMobileMenu}>
                            {t("probiotics")}
                        </Link>
                        <Link to={"/products/antioxidants"} onClick={closeMobileMenu}>
                            {t("antioxidants")}
                        </Link>
                        <Link to={"/products/amino-acids"} onClick={closeMobileMenu}>
                            {t("amino")}
                        </Link>
                        <Link to={"/products/microelements"} onClick={closeMobileMenu}>
                            {t("microelements")}
                        </Link>
                        <Link to={"/products/adsorbents"} onClick={closeMobileMenu}>
                            {t("adsorbent")}
                        </Link>
                    </div>
                </li>
                <NavItem
                    to="/services"
                    text="services"
                    t={t}
                    onClick={closeMobileMenu}
                />
                <NavItem to="/about" text="about" t={t} onClick={closeMobileMenu}/>
                <NavItem
                    to="/contacts"
                    text="contacts"
                    t={t}
                    onClick={closeMobileMenu}
                />
            </ul>
            <LanguageSelector/>
        </nav>
    );
};
