import React, {useEffect, useState} from "react";
import "../styles/pages/Prescription.css";
import "../styles/Style.css"
import {useTranslation} from "react-i18next";
import {Indicator1} from "../components/Indicator";
import {Title} from "../components/Title";
import {Receipt} from "../components/Receipt";
import {ReceiptText} from "../components/ReceiptText";


export const Colorants = () => {
    const json = "colorants"
    const {t} = useTranslation(json);
    const [activeReceiptItem, setActiveReceiptItem] = useState("avixanthin");

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);
    const handleClick = (itemName) => {
        setActiveReceiptItem(itemName);
    };
    return (
        <div className="prescription">
            <Indicator1 text={t("indicator_name")}/>
            <Title name={t("main_title")}/>
            <div className="receipt-container">
                <div onClick={() => handleClick("avixanthin")}>
                    <Receipt text={"avixanthin"} json={json}/>
                </div>
                <div onClick={() => handleClick("avizant")}>
                    <Receipt text={"avizant"} json={json}/>
                </div>
            </div>
            <ReceiptText json={json} text={activeReceiptItem}/>
        </div>
    );
};
