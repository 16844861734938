import React, {useContext, useEffect} from 'react';
import "../styles/pages/Home.css"
import {Link} from "react-router-dom";
import {MobileMenuContext} from "../components/MobileMenu";
import vision from "../assets/vision.png"
import goal from "../assets/goal.png"
import {Stat} from "../components/Stat";
import {useTranslation} from "react-i18next";

export const Home = () => {
    const {t} = useTranslation('home');
    useEffect(() => {
        document.title = t('page_title');
    }, [t]);

    const {closeMobileMenu} = useContext(MobileMenuContext);
    return (<div className="home">
            <div className="hero-section">
                <div className="hero-image">
                    <div className="main-content">
                        <h3>{t("welcome")}</h3>
                        <h1 className="site-title">{t("site_title")}</h1>
                        <p>{t("leading_producer")}</p>
                        <div className="button-container">
                            <Link className="btn outlined-btn" to="/about" onClick={closeMobileMenu}>
                                {t("about_us")}
                            </Link>
                            <Link className="btn filled-btn" to="/products" onClick={closeMobileMenu}>
                                {t("our_products")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-image">
                <div className="section">
                    <div className="section-container-1">
                        <p className="item-1">{t("sammix_group")}</p>
                        <p className="item-2">
                            {t("sammix_group_description")}
                        </p>
                    </div>
                    <div className="section-container-2">
                        <div className="stat-container">
                            <Stat
                                title="1K+"
                                subtitle={t("employees")}
                            />
                            <div className="stat-line"/>
                            <Stat
                                title="10+"
                                subtitle={t("experience")}
                            />
                            <div className="stat-line"/>
                            <Stat
                                title="1.5K+"
                                subtitle={t("clients")}
                            />
                        </div>
                        <Link className="btn section-btn" to="/about" onClick={closeMobileMenu}>
                            {t("more_about_us")}
                        </Link>
                    </div>
                </div>
                <div className="section-1">
                    <div className="section-container-3">
                        <div className="section-container-1">
                            <p className="item-1">{t("mission")}</p>
                            <p className="item-2">
                                {t("mission_description")}
                            </p>
                        </div>
                        <img src={goal} alt="goal"/>
                    </div>
                    <div className="section-container-3-reverse">
                        <div className="section-container-1">
                            <p className="item-1">{t("vision")}</p>
                            <p className="item-2">
                                {t("vision_description")}
                            </p>
                        </div>
                        <img src={vision} alt="vision"/>
                    </div>
                </div>
            </div>
        </div>
    );
}