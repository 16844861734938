import React, {useState} from 'react';
import "../styles/components/Form.css"
import {useTranslation} from "react-i18next";

function Form() {
    const {t} = useTranslation('form');
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        message: ""
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const currentTime = new Date().toLocaleString();

        const message = `
        Yangi xabar:\n\n<b>Yuborilgan vaqt:</b> ${currentTime}\n<b>Ism Familiya:</b> ${formData.firstName} ${formData.lastName}\n<b>Email:</b> ${formData.email}\n<b>Raqam:</b> ${formData.contactNumber}\n<b>Xabar Matni:</b> ${formData.message}
    `;

        try {
            const response = await fetch(`https://api.telegram.org/bot7082017494:AAG7AMivO161Aw5Ucek0x6Fd9ihdwawy-KY/sendMessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id:  -1002158881140,
                    text: message,
                    parse_mode: 'HTML'
                }),
            });

            if (response.ok) {
                // Reset form data after successful submission
                setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    contactNumber: "",
                    message: ""
                });
            } else {
                console.error('Failed to send message:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending message:', error.message);
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    return (
        <form className="form" onSubmit={handleSubmit}>
            <div className="flex">
                <div className="form-title">
                    {t("title")}
                </div>
            </div>
            <div className="flex">
                <label>
                <input className="input" type="text" name="firstName" placeholder={t("first_name")} value={formData.firstName}
                           onChange={handleChange} required/>
                </label>

                <label>
                    <input className="input" type="text" name="lastName" placeholder={t("last_name")} value={formData.lastName}
                           onChange={handleChange} required/>
                </label>
            </div>
            <div className="flex">
                <label>
                    <input className="input" type="email" name="email" placeholder="Email" value={formData.email}
                           onChange={handleChange} required/>
                </label>

                <label>
                    <input className="input" type="tel" name="contactNumber" placeholder={t("phone")}
                           value={formData.contactNumber} onChange={handleChange} required/>
                </label>
            </div>
            <div className="flex">
                <label>
                <textarea className="input01" name="message" placeholder={t("message")} rows="5" value={formData.message}
                          onChange={handleChange} required></textarea>
                </label>
            </div>
            <button type="submit" className="fancy">
                <span className="text">{t("submit")}</span>
            </button>
            <div className='bottom-container'>
                <div className="footer-line"></div>
            </div>
        </form>
);
}

export default Form;
