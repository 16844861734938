import React, {useEffect} from "react";
import {Indicator} from "../components/Indicator";
import {Video} from "../components/Video";
import "../styles/pages/About.css";
import "../styles/Style.css"
import {WhyUs} from "../components/WhyUs";
import {Title} from "../components/Title";
import {useTranslation} from "react-i18next";
import {Partner} from "../components/Partner";


export const About = () => {
    const {t} = useTranslation("about");

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);

    return (
        <div className="about">
            <Indicator text={t("indicator_name")}/>
            <div className="hero">
                <div className="about-hero-item">
                    <Title name={t("main_title")}/>
                    <div className="text" dangerouslySetInnerHTML={{__html: t("main_text")}}/>
                </div>
                <div className="about-video">
                    <Video/>
                </div>
            </div>
            <WhyUs/>
            <Partner/>
        </div>
    );
};
