import React, {useContext, useEffect} from 'react';
import "../styles/pages/NotFound.css"
import notFound from "../assets/404.png"
import {Link} from "react-router-dom";
import {MobileMenuContext} from "../components/MobileMenu";
import {Indicator} from "../components/Indicator";
import {useTranslation} from "react-i18next";

export const NotFound = () => {
    const {closeMobileMenu} = useContext(MobileMenuContext);
    const {t} = useTranslation('404');
    useEffect(() => {
        document.title = "404 - Sammix";
    }, []);

    return (
        <div className="not-found">
            <Indicator text="404"/>
            <div className="image-container">
                <img src={notFound} alt="404"/>
            </div>
            <div className='text-container'>
                <div className="text-1">
                    {t("text1")}
                </div>
                <div className="text-2">
                    {t("text2")}
                </div>
                <div className="text-3">
                    {t("text3")}
                </div>
                <div className="btn-404">
                    <Link to="/" onClick={closeMobileMenu}>{t("back")}</Link>
                </div>
            </div>
        </div>
    );
}