import "../styles/components/Video.css";
import ReactPlayer from "react-player";

export const Video = () => {
    return (
        <div className="video">
            <div className="video-player">
                <ReactPlayer url="https://www.dropbox.com/scl/fi/ruaemo4e1dqaubc5uf7vh/sammix.mp4?rlkey=dfbp0ybewm919ctfk79m9m6r2&st=4v9jxowh&dl=0"
                             controls={true}
                             width="100%"
                             height="100%"
                />
            </div>
        </div>
    );
}
