import "../styles/components/Partner.css"
import {useTranslation} from "react-i18next";
import adisseoImage from "../assets/partner/adisseo.png";
import allTechImage from "../assets/partner/alltech.png";
import aollenImage from "../assets/partner/aollen.png";
import kaeslerImage from "../assets/partner/kaesler.png";
import metexImage from "../assets/partner/metex.png";
import phosAgroImage from "../assets/partner/phosagro.png";
import polifarImage from "../assets/partner/polifar.png";
import zinproImage from "../assets/partner/zinpro.png";


export const Partner = () => {
    const {t} = useTranslation('partner');
    return (
        <div className="partner">
            <div className="partner-text">
                {t("partner")}
            </div>
            <div className="partner-container">
                <img src={polifarImage} alt="Polifar" className="partner-logo"/>
                <img src={phosAgroImage} alt="PhosAgro" className="partner-logo"/>
                <img src={adisseoImage} alt="Adisseo" className="partner-logo"/>
                <img src={allTechImage} alt="AllTech" className="partner-logo"/>
                <img src={aollenImage} alt="Aollen" className="partner-logo"/>
                <img src={kaeslerImage} alt="Kaesler" className="partner-logo"/>
                <img src={metexImage} alt="Metex" className="partner-logo"/>
                <img src={zinproImage} alt="Zinpro" className="partner-logo"/>
            </div>
        </div>
    );
}