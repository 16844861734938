import React from "react";
import "../styles/components/Receipt.css"
import {useTranslation} from "react-i18next";

export const Receipt = (props) => {
    const {json, text} = props
    const {t} = useTranslation(json);
    return (
        <div className={`receipt-item`}>
            <div className={`receipt-item-text`}>
                {t(text)}
            </div>
        </div>
    );
}