import React, {useEffect} from 'react';
import {Indicator} from "../components/Indicator";
import "../styles/pages/Services.css"
import "../styles/Style.css"
import {Title} from "../components/Title";
import {useTranslation} from "react-i18next";

export const Services = () => {
    const {t} = useTranslation("service");

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);

    return (
        <div className="services">
            <Indicator text={t("indicator_name")}/>
            <Title name={t("main_title")}/>
            <div className="hero">
                <div className="services-hero-container">
                    <div className="services-hero-title">
                        {t("hero_title")}
                    </div>
                    <div className="text" dangerouslySetInnerHTML={{__html: t("hero_text")}}/>
                </div>
                <div className="services-hero-container">
                    <div className="services-hero-title">
                        {t("laboratory")}
                    </div>
                    <div className="text" dangerouslySetInnerHTML={{__html: t("laboratory_text")}}/>
                </div>
                <div className="services-hero-container">
                    <div className="services-hero-title">
                        {t("research")}
                    </div>
                    <div className="text" dangerouslySetInnerHTML={{__html: t("research_text")}}/>
                </div>
                <div className="services-hero-container">
                    <div className="services-hero-title">
                        {t("vet")}
                    </div>
                    <div className="text" dangerouslySetInnerHTML={{__html: t("vet_text")}}/>
                </div>
            </div>
        </div>
    );
}
