import "../styles/components/Stat.css"

export const Stat = (props) => {
    const {title, subtitle} = props
    return (
        <div className="stat">
            <div className="stat-title">
                {title}
            </div>
            <div className="stat-subtitle">
                {subtitle}
            </div>
        </div>
    );
}