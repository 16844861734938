import React, {useEffect} from 'react';
import {Indicator} from "../components/Indicator";
import "../styles/pages/Products.css"
import {Title} from "../components/Title";
import design from "../assets/design.png"
import {useTranslation} from "react-i18next";

export const Products = () => {
    const {t} = useTranslation("product");

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);

    return (
        <div className="products">
            <Indicator text={t("indicator_name")}/>
            <div className="products-hero">
                <div className="products-hero-item">
                    <Title name={t("main_title")}/>
                    <div className="products-hero-text">
                        <p>
                            {t("main_text")}
                        </p>
                    </div>
                </div>
                <img className="products-hero-image" src={design} alt="logo"/>
            </div>
            <div className="product-section">
                <div className="product-section-item first-color">
                    <div className="product-section-text">
                        {t("text1")}
                    </div>
                </div>
                <div className="product-section-item second-color">
                    <div className="product-section-text">
                        {t("text2")}
                    </div>
                </div>
            </div>
        </div>
    );
}
