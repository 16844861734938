import "../styles/components/Footer.css"
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Social} from "./Social";
import {MobileMenuContext} from "./MobileMenu";
import {useContext} from "react";

export const Footer = () => {
    const {t} = useTranslation('footer');
    const {closeMobileMenu} = useContext(MobileMenuContext);
    return (
        <div className="footer">
            <div className="top-container">
                <div className="outer-container">
                    <div className="footer-title">
                        {t("product_title")}
                    </div>
                    <div className="inner-container">
                        <div className="column">
                            <Link className="footer-link" to={t("premix_link")} onClick={closeMobileMenu}>
                                {t("premix")}
                            </Link>
                            <Link className="footer-link" to={t("compound_link")} onClick={closeMobileMenu}>
                                {t("compound")}
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="outer-container">
                    <div className={"footer-title"}>
                        {t("company_title")}
                    </div>
                    <div className="inner-container">
                        <div className="column">

                            <Link className="footer-link" to={t("home_link")} onClick={closeMobileMenu}>
                                {t("home")}
                            </Link>


                            <Link className="footer-link" to={t("about_link")} onClick={closeMobileMenu}>
                                {t("about")}
                            </Link>
                            <Link className="footer-link" to={t("contact_link")} onClick={closeMobileMenu}>
                                {t("contact")}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="outer-container">
                    <div className="footer-title">
                        {t("follow")}
                    </div>
                    <div className="inner-container">
                        <div className="column">
                            <Link className="footer-link" to={"tel:+998702010002"} onClick={closeMobileMenu}>
                                +998 (70) 201 00 02
                            </Link>
                            <Link className="footer-link" to={"mailto:info@sammix.uz"} onClick={closeMobileMenu}>
                                info@sammix.uz
                            </Link>
                        </div>
                    </div>
                    <Social/>
                </div>
            </div>
            <div className='bottom-container'>
                <div className="footer-line"></div>
                <div className="footer-text">
                    {t("text")}
                </div>
            </div>
        </div>
    );
}
