import React from "react";
import "../styles/components/ProductDetail.css"
import {useTranslation} from "react-i18next";

export const ProductDetail = (props) => {
    const {json, Icon, name} = props
    const {t} = useTranslation(json);
    return (
        <div className="product-detail">
            <div className="product-detail-container">
                <Icon className="product-detail-icon"/>
                <div className="product-detail-title">
                    {t(name.concat("_title"))}
                </div>
            </div>
            <div className="product-detail-text" dangerouslySetInnerHTML={{__html: t(name.concat("_text"))}}>
            </div>
        </div>
    );
}