import React, {useEffect, useState} from "react";
import "../styles/pages/Prescription.css";
import "../styles/Style.css"
import {useTranslation} from "react-i18next";
import {Indicator1} from "../components/Indicator";
import {Title} from "../components/Title";
import {Receipt} from "../components/Receipt";
import {ReceiptText} from "../components/ReceiptText";


export const Microelements = () => {
    const json = "microelements"
    const {t} = useTranslation(json);
    const [activeReceiptItem, setActiveReceiptItem] = useState("zinc");

    useEffect(() => {
        document.title = t('page_title');
    }, [t]);
    const handleClick = (itemName) => {
        setActiveReceiptItem(itemName);
    };
    return (
        <div className="prescription">
            <Indicator1 text={t("indicator_name")}/>
            <Title name={t("main_title")}/>
            <div className="receipt-container">
                <div onClick={() => handleClick("zinc")}>
                    <Receipt text={"zinc"} json={json}/>
                </div>
                <div onClick={() => handleClick("cobalt_sulfate")}>
                    <Receipt text={"cobalt_sulfate"} json={json}/>
                </div>
                <div onClick={() => handleClick("iron_sulfate")}>
                    <Receipt text={"iron_sulfate"} json={json}/>
                </div>
                <div onClick={() => handleClick("manganese_sulfate")}>
                    <Receipt text={"manganese_sulfate"} json={json}/>
                </div>
                <div onClick={() => handleClick("calcium_iodate")}>
                    <Receipt text={"calcium_iodate"} json={json}/>
                </div>
                <div onClick={() => handleClick("copper_sulfate")}>
                    <Receipt text={"copper_sulfate"} json={json}/>
                </div>
                <div onClick={() => handleClick("sodium_selenite")}>
                    <Receipt text={"sodium_selenite"} json={json}/>
                </div>
                <div onClick={() => handleClick("selen")}>
                    <Receipt text={"selen"} json={json}/>
                </div>
                <div onClick={() => handleClick("propathmn")}>
                    <Receipt text={"propathmn"} json={json}/>
                </div>
                <div onClick={() => handleClick("propathfe")}>
                    <Receipt text={"propathfe"} json={json}/>
                </div>
            </div>
            <ReceiptText json={json} text={activeReceiptItem}/>
        </div>
    );
};
