import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Home} from "./pages/Home";
import {Products} from "./pages/Products";
import {Services} from "./pages/Services";
import {About} from "./pages/About";
import {Contacts} from "./pages/Contacts";
import {NotFound} from "./pages/NotFound";
import {Navbar} from "./components/Navbar";
import {MobileMenuProvider} from "./components/MobileMenu";
import {I18nextProvider} from 'react-i18next';
import i18n from './I18n';
import React, {Suspense} from "react";
import {Premix} from "./pages/Premix";
import {Loading} from "./pages/Loading";
import Form from "./components/Form";
import {Footer} from "./components/Footer";
import {Compound} from "./pages/Compound";
import {Vitamins} from "./pages/Vitamins";
import {Colorants} from "./pages/Colorants";
import {AminoAcids} from "./pages/AminoAcids";
import {Probiotics} from "./pages/Probiotics";
import {Microelements} from "./pages/Microelements";
import {Enzymes} from "./pages/Enzymes";
import {Antioxidants} from "./pages/Antioxidants";
import ScrollToTop from "./components/ScrollToTop";
import {Adsorbent} from "./pages/Adsorbent";

function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <div className="App">
                <MobileMenuProvider>
                    <Router>
                        <Navbar/>
                        <Suspense fallback={<Loading/>}>
                            <ScrollToTop/>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/products" element={<Products/>}/>
                                <Route path="/products/sammix-premix" element={<Premix/>}/>
                                <Route path="/products/compound-feed" element={<Compound/>}/>
                                <Route path="/products/vitamins" element={<Vitamins/>}/>
                                <Route path="/products/colorants" element={<Colorants/>}/>
                                <Route path="/products/amino-acids" element={<AminoAcids/>}/>
                                <Route path="/products/probiotics" element={<Probiotics/>}/>
                                <Route path="/products/microelements" element={<Microelements/>}/>
                                <Route path="/products/antioxidants" element={<Antioxidants/>}/>
                                <Route path="/products/adsorbents" element={<Adsorbent/>}/>
                                <Route path="/products/enzymes" element={<Enzymes/>}/>
                                <Route path="/services" element={<Services/>}/>
                                <Route path="/about" element={<About/>}/>
                                <Route path="/contacts" element={<Contacts/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                        </Suspense>
                        <Form/>
                        <Footer/>
                    </Router>
                </MobileMenuProvider>
            </div>
        </I18nextProvider>
    );
}

export default App;
